import { groq } from 'next-sanity'
import { getClient } from 'clients/sanity'
import type { WebsiteSettings } from 'utils/types/sanity/generated-schema-types'
import { getQueryHelpers, QueryHelpers } from './helpers/get-query-helpers'
import type { FullHomePage, FullPage, Page } from 'utils/types/sanity/page'

export const fetchPagesWithMinimalData = async (): Promise<Page['slug'][]> =>
  getClient(false).fetch<Page['slug'][]>(
    groq`*[_type == "page" && defined(slug.current)][].slug.current`
  )

export const getPageWithSettingsQueryHelpers = (): QueryHelpers<
  { slug: string },
  FullPage
> =>
  getQueryHelpers(
    () => groq`
      *[_type == "page" && slug.current == $slug][0] {
        _id,
        _updatedAt,
        title,
        body,
        "slug": slug.current,
        "websiteSettings": *[_type == "websiteSettings"][0],
      }
    `
  )

export const getHomePageWithSettingsQueryHelpers = (): QueryHelpers<{}, FullHomePage> =>
  getQueryHelpers(
    () => groq`
      *[_type == "homePage" && _id == "homePage"][0] {
        _id,
        rollingText,
        seo,
        "websiteSettings": *[_type == "websiteSettings"][0],
      }
    `
  )

export const getPageWithWebsiteQueryHelpers = (): QueryHelpers<{}, WebsiteSettings> =>
  getQueryHelpers(() => groq`*[_type == "websiteSettings"][0]`)
