import type { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import {
  Collaboration,
  Community,
  Expectations,
  Hero,
  Newsletter,
  Platforms,
} from 'components/ui'
import { getHomePageWithSettingsQueryHelpers } from 'data/page'
import { MainLayout } from 'components/layouts'
import type { FullHomePage } from 'utils/types/sanity/page'
import { config } from 'config/config'

const { fetch, usePreviewSubscription } = getHomePageWithSettingsQueryHelpers()

interface HomeProps {
  data: {
    homePage: FullHomePage
  }
  preview: boolean
}

const Home: NextPage<HomeProps> = ({ data, preview }) => {
  const { data: home } = usePreviewSubscription(data.homePage, {}, preview)

  if (!home) {
    throw new Error('Home page no longer exists!')
  }

  return (
    <MainLayout
      settings={home.websiteSettings}
      ogImage={home.seo?.image}
      path='/'
      preview={preview}
    >
      <Hero
        discordLink={home.websiteSettings.discord || ''}
        rollingText={home.rollingText || ''}
      />
      <Collaboration />
      <Platforms />
      <Expectations />
      <Community discordLink={home.websiteSettings.discord || ''} background='gray' />
      <Newsletter />
    </MainLayout>
  )
}

export const getStaticProps: GetStaticProps<HomeProps> = async ({
  preview = false,
  locale = config.defaultLocale,
}) => {
  const homePage = await fetch({}, preview)

  return {
    props: {
      data: { homePage },
      preview,
      ...(await serverSideTranslations(locale, ['common', 'index', 'footer'])),
    },
    revalidate: config.next.pageRevalidateDelay,
  }
}

export default Home
