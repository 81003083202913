import {
  getClient,
  usePreviewSubscription as initialUsePreviewSubscription,
} from 'clients/sanity'

export type Groq = string

/**
 * Object used to simplify working with Sanity queries, while still having full SSR and
 * real-time preview functionality.
 */
export interface QueryHelpers<Args extends Record<string, unknown> | undefined, Result> {
  /**
   * Perform the query with the specified args.
   */
  fetch: (args: Args, preview: boolean) => Promise<Result>
  /**
   * If preview === true, will listen to the query to get real-time preview functionality.
   */
  usePreviewSubscription: (
    initialData: Result,
    args: Args,
    preview: boolean
  ) => {
    data: Result
    loading: boolean
    error: Error | undefined
  }
}

/**
 * Get query helpers to query Sanity. This is necessary to make sure that the backend (for SSR)
 * and frontend (for Real-time preview), share the exact same querying code. This way, we are sure
 * the queries are built the exact same way.
 *
 * @param buildQuery A function used to build the query from the arguments passed, in case we
 * want to do any preprocessing using the args before returning the query. Usually, this should
 * not happen and the query should be using the args using parameters (e.g.) `$slug`).
 * @param initialArgs Arguments that should be supplied to the query but not needed to be passed
 * by `fetch` and `usePreviewSubscription`.
 */
export const getQueryHelpers = <Args extends Record<string, unknown> | undefined, Result>(
  buildQuery: () => Groq,
  initialArgs?: Record<string, unknown>
): QueryHelpers<Args, Result> => ({
  fetch: (args, preview) =>
    getClient(preview).fetch(buildQuery(), {
      ...initialArgs,
      ...args,
    }),
  usePreviewSubscription: (initialData, args, preview) =>
    initialUsePreviewSubscription<Result>(buildQuery(), {
      initialData,
      enabled: preview,
      params: {
        ...initialArgs,
        ...args,
      },
    }),
})
